import * as React from "react";
import { QrReader } from "react-qr-reader";
import { Drawer, Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { getReceipt } from "../../services/services";

const DrawerQr = () => {
  const [state, setState] = React.useState({
    bottom: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    setState({ ...state, [anchor]: open });
  };

  const anchor = "bottom";

  React.useEffect(() => {
    window.addEventListener("openScanQr", (event) => {
      setState({ ...state, [anchor]: true });
    });
  }, []);

  const handleGetReceipt = async (content) => {
    const contentInt = parseInt(content);
    const response = await getReceipt(contentInt);
    if (response.data.success) {
      if (response.data.data.active) {
        window.location.href = `/yms-portal/checkinout/${contentInt}`;
      }
    }
    return response;
  };

  return (
    <>
      <Drawer
        style={{ zIndex: 35 }}
        anchor={anchor}
        open={state[anchor]}
        onClose={toggleDrawer(anchor, false)}
        PaperProps={{
          style: {
            height: "100%",
          },
        }}
      >
        <Grid container style={{ padding: "20px", backgroundColor: "#22224f" }}>
          <Grid col={2} style={{ display: "flex", alignSelf: "center" }}>
            <ArrowBackIcon
              onClick={toggleDrawer(anchor, false)}
              style={{ color: "white" }}
            />
          </Grid>
          <Grid col={10}>
            <Typography
              variant="h6"
              style={{ color: "white", paddingLeft: "30px", opacity: "70%" }}
            >
              QR Reader
            </Typography>
          </Grid>
        </Grid>
        <QrReader
          onResult={(result, error) => {
            if (!!result) {
              const resultJson = JSON.parse(result.text);
              if (resultJson.type === "chassisRental") {
                window.history.pushState(
                  {},
                  "",
                  `${process.env.REACT_APP_URL_TO_REDIRECT_CHASSIS}?CarrierScacCode=${resultJson.scac_code}&AgreementId=${resultJson.agreementId}`
                );
                setState({ ...state, [anchor]: false });
              } else {
                setState({ ...state, [anchor]: false });
                handleGetReceipt(result.text);
              }
            }
            if (!!error) {
              console.info(error);
            }
          }}
          constraints={{ facingMode: "environment" }}
        />
      </Drawer>
    </>
  );
};

export default DrawerQr;
