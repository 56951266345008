import axios from "axios";

const headers = {
    "Ocp-Apim-Subscription-Key": process.env.REACT_APP_APIMANAGEMENT_HEADER,
    "Content-Type": "application/json",
};

export const getReceipt = async (content) => {

    const config = {
        headers: headers,
        method: 'get',
        url: process.env.REACT_APP_GET_RECEIPT,
        params: {
            receipt_number: content
        }
    };

    const response = await axios(config)
    return response
}
